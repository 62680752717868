import React from 'react'
import Layout from '../../components/layout'
import PageHeader from '../../components/header/page-header'
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../../components/seo'
import SpyMenu from '../../components/menus/spyMenu'
import BackgroundImage from 'gatsby-background-image'

import { Section2IconList } from '../../components/Section2IconList'
import PrimaryButton from '../../components/buttons/primary_button'

import Image from 'gatsby-image'
import WhyChooseUsSection from '../../components/whyChooseUsSection'
import ServiceIconBoxes from '../../components/ServiceIconBoxes'
import CustomersSections from '../../components/customersSection'
import Testimonial from '../../components/Testimonial'
import ClientCarousel from '../../components/ClientCarousel'

const ITConsultancy = () => {
  const data = useStaticQuery(graphql`
    query ITConsultancy {
      ...IndustryWeServeFragment
      bgImage: file(relativePath: { eq: "services/it-consultancy-bg.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ipad: file(relativePath: { eq: "home/ipad.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      teamImage: file(
        relativePath: {
          eq: "services/business-concept-development-teamImage.jpg"
        }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testomonial1: file(relativePath: { eq: "testomonial/2.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testomonial2: file(relativePath: { eq: "testomonial/1.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testimonials: allTestimonialsJson {
        nodes {
          description
          className
          image {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          name
          role
        }
      }
    }
  `)

  const testimonials = data.testimonials.nodes

  const it_support_routes = [
    {
      name: 'Benefits',
      route: 'benefits',
      offset: -10
    },
    {
      name: 'Solutions',
      route: 'solutions',
      offset: 50
    },
    {
      name: 'Case Studies',
      route: 'case-studies',
      offset: -55
    },
    {
      name: 'Customers',
      route: 'customers',
      offset: -60
    },
    {
      name: 'Why Choose Us',
      route: 'why-choose-us',
      offset: -50
    },
    {
      name: 'Other Services',
      route: 'other-services',
      offset: -10
    }
  ]

  const teamImage = data.teamImage.childImageSharp.fluid
  // const backgroundImage = data.teamImage.childImageSharp.fluid
  const ipadImage = data.ipad.childImageSharp.fluid

  const testomonials = [
    {
      name:
        'Eduplus - Mr.Sujith Liyanage -(Director/Teacher) -  Viduna Institute',
      image: data.testomonial1.childImageSharp.fluid,
      description:
        'Eduplus” is the great product which I found to manage my institute without any hassle from any involved parties such as students, parents & institute staff. The Team Explorelogy worked with us in a very passionate and energetic way with a positive approach that always increased my satisfaction level on their product.',
      className: 'testomonial-box custom-border'
    },
    {
      name: 'M.K. Daya Priyantha (Director/CEO) - Invos Global (PVT) LTD ',
      image: data.testomonial2.childImageSharp.fluid,
      description:
        'They have the power of Conceptualization of our business idea and the concept from the Digital perspective which helps for the betterment of the company, In simple words they think bigger than we do as business owners. One of my best decisions which I have taken and never regret is selecting “Explorelogy” as an IT partner.',
      className: 'testomonial-box-2'
    }
  ]

  const pageTitle = 'IT Consultancy'
  const pageDescription =
    'An expert to guide you on the latest industry trends, essential transformations, and targeted IT strategies'
  return (
    <Layout>
      <SEO title={pageTitle} description={pageDescription} />
      <PageHeader
        className="padding-180"
        title={pageTitle}
        subtitle={pageDescription}
        background={data.bgImage.childImageSharp.fluid}
        thisPage={pageTitle}
        previousPage="IT Services"
        previousPageLink="/services/"
      />
      <SpyMenu routes={it_support_routes} />
      <div id="benefits" className="container-fluid pt-3">
        <div className="col-md-6 mx-auto">
          <h2 style={{ textAlign: 'center', textTransform: 'uppercase' }}>
            Extraordinary solution for your ordinary problem
          </h2>
          {/* <div style={{ height: 20 }}></div> */}
          <div>
            <p style={{ textAlign: 'center' }}>
              Many challenges in today’s business world are technology related.
              Having been in the industry for a considerable time and having a
              team of IT experts on board enables us to provide you with the
              guidance and insight you need without any technical involvement
              from our end.
              <br />
              We can assess your business needs and objectives, advise you on
              what IT resources you should invest in, and support you when you
              face any IT related challenges.
            </p>
          </div>
          <div style={{ height: 60 }}></div>
        </div>
        <div className="container">
          <div className="row">
            {benefits_data.map((item, index) => {
              return (
                <BenefitBox
                  key={index}
                  className={item.className}
                  title={item.title}
                  description={item.description}
                />
              )
            })}
          </div>
        </div>
        <div style={{ height: 200 }}></div>
      </div>
      <div className="container-fluid bg-white">
        <div className="row ">
          <div className="col-8 col-md-4 mx-auto">
            <div
              style={{ marginTop: -120 }}
              className=" text-center position-relative"
            >
              <div>
                <Image className="img-fluid" fluid={ipadImage}></Image>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mx-auto pt-5">
          <h2
            className="what-service"
            style={{ textAlign: 'center', textTransform: 'uppercase' }}
          >
            What is IT Consultancy ?
          </h2>
          {/* <div style={{ height: 20 }}></div> */}
          <div>
            <p style={{ textAlign: 'center' }}>
              To achieve your business objectives by using information
              technology best practices and to manage or review your field of
              activities by our team for your company betterment. Meet our
              advising team and feel the difference to where you can scale up
              your business or your strategies from our experienced eye with the
              touch of technology.
            </p>
          </div>
          <div style={{ height: 60 }}></div>
        </div>
        <div className="col-md-10 mt-0 mb-0 mb-0 mx-auto">
          <div className="row">
            <div className="col-md-7">
              <BackgroundImage
                Tag="div"
                fluid={teamImage}
                className="section-2-team"
              ></BackgroundImage>
            </div>
            <div className="col-md-5">
              <div className="container-box light-blue">
                <h2
                  className="secret-behind"
                  style={{
                    textTransform: 'uppercase',
                    fontWeight: '100',
                    marginTop: 20
                  }}
                >
                  Secret Behind Us - Our force
                </h2>
                <div style={{ height: 10 }}></div>
                <ul className="section-2-iconlist">
                  <Section2IconList>
                    Highest rate of retantion 100% for all staff
                  </Section2IconList>
                  <Section2IconList>
                    Our team passionate to welcome challenges
                  </Section2IconList>
                  <Section2IconList>
                    Long term relationships lead to strong business strategies.
                  </Section2IconList>
                  <Section2IconList>
                    Our clients are totally secured by our expert teams.
                  </Section2IconList>
                </ul>
                <div style={{ height: 20 }}></div>
                <PrimaryButton
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '90%'
                  }}
                  title="Find Out More"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="solutions" className="container-fluid bg-white py-5"></div>
      <div id="case-studies" className="container"></div>
      <CustomersSections>
        <div className="container">
          <ClientCarousel clientImages={data.clientImages} />
        </div>
      </CustomersSections>
      {/* <Testimonial testomonial={testimonials} /> */}

      <WhyChooseUsSection />
      <div id="other-services" className="container-fluid">
        <div className="row mx-0">
          <div className="col-11 col-md-10 mx-auto">
            <div className="column-inner">
              <div style={{ width: '100%' }} className="separator">
                <span className="sep_holder">
                  <span className="sep_line"></span>
                </span>
                <p
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    marginBottom: 0,
                    fontSize: '1rem',
                    fontWeight: '100',
                    textAlign: 'center'
                  }}
                >
                  Diversified solutions to suit your requirements
                </p>
                <span className="sep_holder">
                  <span className="sep_line"></span>
                </span>
              </div>
              <div style={{ height: 40 }}></div>
              {/* service icon boxes */}
              <ServiceIconBoxes />
              {/* service icon boxes */}
              <div style={{ height: 20 }}></div>
              <div className="text-column pb-5">
                <p style={{ textAlign: 'center' }}>
                  IT services built specifically for your business. &nbsp;
                  <a className="link" href="/#">
                    Find your solution
                  </a>
                </p>
              </div>
              {/* <div style={{ height: 230 }}></div> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const BenefitBox = ({ className, title, description }) => {
  return (
    <div className="col-md-4">
      <div className={`benefit-box ${className}`}>
        <h4 style={{ marginTop: 0, marginBottom: 15, color: '#fff' }}>
          <span>{title}</span>
        </h4>
        <p style={{ color: '#fff' }}>{description}</p>
      </div>
    </div>
  )
}

const benefits_data = [
  {
    title: 'Cost control',
    description:
      'Investing in technologies with the right consultation can be cost effective and profitable for businesses in the long run. ',
    className: 'blue'
  },
  {
    title: 'Business benefit',
    description:
      'Developing and implementing well planned technologies that suit your business aims can enable maximised marketing, drive profits, and increase scalability.',
    className: 'lightorange'
  },
  {
    title: 'Suited for you',
    description:
      'If you are looking for an experienced consultant to guide you through your IT related concerns, our IT consultancy services are just right for you.',
    className: 'darkblue'
  }
]

export default ITConsultancy
